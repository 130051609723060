import slick from "slick-carousel";

const $slider = $('.about-index__slider-list');

$('.about-index__slider-list').slick({
  speed: 300,
  slidesToShow: 1,
  centerMode: true,
  arrows: false,
  slidesToScroll: 1,
  centerPadding: '200px',
  responsive: [
    {
      breakpoint: 699,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
  ],
});

if ($slider.length > 0) {

  $('[data-about-arrow]').on('click', function (e) {
    e.preventDefault();
    if ($(this).data('about-arrow') == 'left') {
      $slider.slick('slickPrev');
    } else if ($(this).data('about-arrow') == 'right') {
      $slider.slick('slickNext');
    }
  });
}

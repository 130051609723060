import Jax from '../../components/jax/jax';

function initCategory() {
  const filterForm = document.querySelector('[data-filter-form');

  if (filterForm) {
    const inputs = filterForm.querySelectorAll('input[type="checkbox"], select');
    const sortLinks = document.querySelectorAll('[data-sort-link]');
    const sortSelect = filterForm.querySelector('[data-sort-field] select');
    const priceInputs = filterForm.querySelectorAll('[data-price-field] input');
    const submitEvent = new Event('submit');
    const changeEvent = new Event('change');
    const filterTogglers = filterForm.querySelectorAll('[data-filter-toggler]');
    const filterShowLink = filterForm.querySelector('[data-filter-show]');

    filterShowLink.addEventListener('click', (e) => {
      e.preventDefault();
      const toggleText = filterShowLink.innerHTML;
      filterShowLink.innerHTML = filterShowLink.dataset.toggleText;
      filterShowLink.dataset.toggleText = toggleText;
      document.querySelector('.filter-form__content').classList.toggle('_show');
    });

    inputs.forEach((input) => {
      input.addEventListener('change', () => {
        filterForm.dispatchEvent(submitEvent);
      });
    });

    sortLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        if (!link.classList.contains('_active')) {
          document.querySelector('[data-sort-link]._active').classList.remove('_active');
          link.classList.add('_active');
          sortSelect.value = link.dataset.sortLink;
          sortSelect.dispatchEvent(changeEvent);
        }
      });
    });

    priceInputs.forEach((input) => {
      input.addEventListener('change', _.debounce(() => {
        filterForm.dispatchEvent(submitEvent);
      }, 300));
    });

    filterTogglers.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        link.closest('.filter-form__field-block').classList.toggle('_active');
      });
    });


    filterForm.addEventListener('submit', function (e) {
      e.preventDefault();
      const jax = new Jax(this.getAttribute('action'), this.getAttribute('method'));
      const qwer = new FormData(this);
      qwer.forEach((wtf) => {
        console.log(wtf);
      });
      jax.send(new FormData(this)).then((html) => {
        const page = document.createElement('div');
        page.innerHTML = html;
        const newData = page.querySelector('[data-filter-data]');
        const currentData = document.querySelector('[data-filter-data]');

        currentData.innerHTML = newData.innerHTML;
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', initCategory);
import noUiSlider from "nouislider";
import wNumb from "wnumb";
import _ from "lodash";
import css from "nouislider/distribute/nouislider.css";

(function ($) {
  let rangeInput = function () {
    return rangeInput.init.apply(this, arguments);
  };

  $.extend(rangeInput, {
    options: {
      step: 1,
      min: 0,
      max: 0,
      postfix: '',
      from: 0,
      to: 0,
    },
    fromInput: undefined,
    toInput: undefined,
    fromShow: undefined,
    toShow: undefined,
    line: undefined,
    slider: undefined,
    element: undefined,
    init(element, options) {
      this.element = element;
      this.options = $.extend(this.options, options, this.element.data());
      this.fromInput = $(element).find('[data-range-from-input]');
      this.toInput = $(element).find('[data-range-to-input]');
      this.fromShow = $(element).find('[data-range-from-show]');
      this.toShow = $(element).find('[data-range-to-show]');
      this.line = $(element).find('[data-range-line]');
      this.bind();
    },
    bind() {
      this.slider = noUiSlider.create(this.line[0], {
        step: this.options.step,
        range: {
          'min': this.options.min ? this.options.min : 0,
          'max': this.options.max ? this.options.max : 0
        },
        connect: true,
        start: [ this.options.from, this.options.to ],
        format: wNumb({
          decimals: 0,
          thousand: ' ',
          suffix: this.options.postfix,
        })
      });
      this.slider.on('update', (values, handle) => {
        this.fromInput.val(values[0]);
        this.toInput.val(values[1]);
        this.fromShow.html(values[0]);
        this.toShow.html(values[1]);
        const event = new Event('change');
        this.fromInput[0].dispatchEvent(event);
        this.toInput[0].dispatchEvent(event);
      });
      let setter = _.debounce(() => {
        this.slider.set([
          this.fromInput.val(),
          this.toInput.val()
        ]);
      }, 2000);
      this.fromInput.on('keyup', setter);
      this.toInput.on('keyup', setter);
    }
  });

  return $.fn.rangeInput = function (options) {
    return rangeInput.init(this, options);
  };
}($));

$('[data-range-input]').each(function () {
  $(this).rangeInput();
});
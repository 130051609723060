import LiveEvent from '../components/live/live';
import vars from '../scss/_settings/_settings.scss';


window.onscroll = function () {
  headerSticky();
};


function headerSticky() {
  const headerMain = document.querySelector('[data-header-main]');
  const headerTop = document.querySelector('[data-header-top]');
  const topWindows = window.pageYOffset;
  const topHeight = headerTop.offsetHeight;

  if (topHeight > 0) {
    if (topWindows > topHeight && !headerMain.classList.contains('_sticky')) {
      headerMain.classList.add('_sticky');
    } else if (topWindows <= topHeight && headerMain.classList.contains('_sticky')) {
      headerMain.classList.remove('_sticky');
    }
  }
}

function hoverableHandler() {
  const mediumPoint = parseInt(vars['medium-breakpoint']);

  document.querySelectorAll('[data-hoverable-item]').forEach((hoverableItem) => {
    hoverableItem.addEventListener('mouseleave', () => {
      if (window.innerWidth >= mediumPoint) {
        hoverableItem.classList.remove('_hover');
      }
    });
    hoverableItem.addEventListener('mouseenter', () => {
      if (window.innerWidth >= mediumPoint) {
        hoverableItem.classList.add('_hover');
      }
    });

    if (hoverableItem.childNodes.length > 3) {
      const link = hoverableItem.querySelector('a');
      const parentContainer = hoverableItem.parentNode;

      link.addEventListener('click', (e) => {
        e.preventDefault();
        if (window.innerWidth < mediumPoint && !hoverableItem.classList.contains('_hover')) {
          hoverableItem.classList.add('_hover');
          parentContainer.childNodes.forEach((element) => {
            if (element !== hoverableItem && element.nodeType === 1) {
              element.classList.add('_hide');
            }
          });
        }
      });
    }
  });

  document.querySelector('[data-burger]').addEventListener('click', (e) => {
    const menu = document.querySelector('[data-catalog-menu]');
    const burger = e.target;
    if (burger.classList.contains('_active')) {
      burger.classList.remove('_active');
      menu.classList.remove('_active');
      menu.querySelectorAll('._hover,._hide').forEach((item) => {
        item.classList.remove('_hover');
        item.classList.remove('_hide');
      });
    } else {
      burger.classList.add('_active');
      menu.classList.add('_active');
    }
  });

  document.querySelectorAll('[data-back-link]').forEach((item) => {
    item.addEventListener('click', (e) => {
      const parent = item.closest('[data-hoverable-item]');
      const parentContainer = parent.parentNode;

      setTimeout(() => {
        parentContainer.querySelectorAll('._hover,._hide').forEach((resetItem) => {
          resetItem.classList.remove('_hover');
          resetItem.classList.remove('_hide');
        });
      }, 100);
    });
  });
}


document.addEventListener('DOMContentLoaded', hoverableHandler);
function initReviews() {
  const reviewItems = document.querySelectorAll('[data-stage-item]');
  if (reviewItems) {
    reviewItems.forEach(function (reviewItem) {
      const textWrapper = reviewItem.querySelector('.stage__description-wrapper');
      const text = reviewItem.querySelector('.stage__description');
      const showLink = reviewItem.querySelector('.stage__button');
      if (showLink) {
        if ((textWrapper && text) && (text.offsetHeight <= textWrapper.offsetHeight)) {
          showLink.classList.add('_hide');
        }
        showLink.addEventListener('click', function (e) {
          e.preventDefault();
          if (reviewItem.classList.contains('_open')) {
            reviewItem.classList.remove('_open');
            reviewItem.removeAttribute('style');
          } else {
            reviewItem.classList.add('_open');
          }

          let toggleText = showLink.dataset.toggleText;
          showLink.dataset.toggleText = showLink.innerHTML;
          showLink.innerHTML = toggleText;
        });
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", initReviews);
require('../scss/app.scss');

window.noZensmooth = true;

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/ajax_redirect.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./slider.js');
require('./slick-slider.js');

require('./field/phone.js');
require('./field/range.js');
require('../components/smart-tabs/smart-tabs');

require('./menu.js');
require('./catalog/index.js');
require('./cart.js');
require('./depend.js');
require('./conacts.js');
require('./reviews.js');
function initMapYandexContacts() {
  const mapElement = document.querySelector('[data-contacts-map]');
  if (mapElement) {
    let addresses = [];
    document.querySelectorAll('[data-address]').forEach((element) => {
      const lat = parseFloat(element.dataset.addressLat);
      const lng = parseFloat(element.dataset.addressLng);
      addresses.push({
        lat,
        lng,
        address: element.dataset.address,
      });
    });

    const centerLat = addresses.reduce((accumulator, currentValue) => accumulator + currentValue.lat, 0) / addresses.length;
    const centerLng = addresses.reduce((accumulator, currentValue) => accumulator + currentValue.lng, 0) / addresses.length;

    console.log(addresses);

    let myMap = new ymaps.Map(mapElement, {
      center: [centerLat, centerLng],
      zoom: mapElement.dataset.zoom ? mapElement.dataset.zoom : 15,
      controls: ['zoomControl', 'fullscreenControl'],
    }, {
      searchControlProvider: 'yandex#search',
    });

    addresses.forEach((address) => {
      const myPlacemark = new ymaps.Placemark([address.lat, address.lng], {}, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: '/static/images/base/placemark.svg',
        // Размеры метки.
        iconImageSize: [43, 57],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-21, -57],
      });

      myMap.behaviors.disable('scrollZoom');
      if (window.innerWidth <= 1024) {
        myMap.behaviors.disable('drag');
      }
      myMap.geoObjects.add(myPlacemark);
    });
  }
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMapYandexContacts);
  }
});
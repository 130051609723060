import { tns } from 'tiny-slider/src/tiny-slider';
import '../../node_modules/tiny-slider/src/tiny-slider.scss';

function bindArrows(left, right, slider) {
  const leftArrow = document.querySelector(left);
  const rightArrow = document.querySelector(right);

  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      slider.goTo('prev');
    });
  }

  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      slider.goTo('next');
    });
  }
}

const offerSliderElement = document.querySelector('[data-slider-offer]');
if (offerSliderElement) {
  const sliderActivity = tns({
    container: '[data-slider-offer]',
    items: 1,
    speed: 700,
    controls: false,
    center: true,
    loop: false,
    swipeAngle: false,
    rewind: true,
    slideBy: 'page',
    nav: false,
    onInit: () => {
      offerSliderElement.classList.add('_hidden');
    },
  });
  bindArrows('[data-offer-left]', '[data-offer-right]', sliderActivity);
}

if (document.querySelector('[data-slider-stock]')) {
  const sliderStock = tns({
    container: '[data-slider-stock]',
    items: 4,
    speed: 700,
    controls: false,
    loop: false,
    swipeAngle: false,
    slideBy: 1,
    nav: false,
    rewind: true,
    responsive: {
      1200: {
        items: 4,
      },
      767: {
        items: 2,
        disable: false,
      },
      320: {
        disable: true,
      },
    },
  });
  bindArrows('[data-stock-left]', '[data-stock-right]', sliderStock);
}

if (document.querySelector('[data-slider-others]')) {
  const sliderOthers = tns({
    container: '[data-slider-others]',
    items: 4,
    speed: 700,
    controls: false,
    swipeAngle: false,
    slideBy: 1,
    nav: false,
    responsive: {
      1200: {
        items: 4,
      },
      767: {
        items: 2,
        disable: false,
      },
      320: {
        disable: true,
      },
    },
  });
  bindArrows('[data-others-left]', '[data-others-right]', sliderOthers);
}
import Jax from "../../components/jax/jax.js";

function initProduct() {
  const element = document.querySelector('[data-product-panel]');
  if (element) {
    initPanel(element);
  }
}

function initPanel(element) {
  const panel = new productPanel(element);
}

class productPanel {
  constructor(element) {
    this.element = element;
    if (this.init()) {
      this.bind();
    }
  }

  init() {
    if (!this.element) {
      return false;
    }

    this.url = '/catalog/calc';
    this.minCount = 1;
    this.product = this.element.dataset.productPanel;
    if (!this.product) {
      return false;
    }

    if (!this.initMeasures() || !this.initInterface()) {
      return false;
    }

    return true;
  }

  bind() {
    this.bindMeasures();
    this.bindInput();
    if (this.buttonDec && this.buttonInc) {
      this.bindButtons();
    }
  }

  bindInput() {
    this.inputQuantity.addEventListener('keyup', () => {
      const value = this.inputQuantity.value;
      if (value !== '' && value.toString().slice(-1) !== '.') {
        this.setCurrentCount(this.prepareCount(value));
      }
    });
  }

  /**
   * Навешиваем события на кнопки
   */
  bindButtons() {
    this.buttonDec.addEventListener('click', (e) => {
      e.preventDefault();
      const count = this.getCurrentCount();
      const newCount = this.prepareCount(parseFloat(count) - 1);
      if (newCount >= this.minCount) {
        this.setCurrentCount(newCount);
      }
    });

    this.buttonInc.addEventListener('click', (e) => {
      e.preventDefault();
      let count = this.getCurrentCount();
      count = this.prepareCount(parseFloat(count) + 1);
      this.setCurrentCount(count);
    });
  }

  /**
   * Навешиваем события на меры
   */
  bindMeasures() {
    this.measureList.forEach((measureObject) => {
      measureObject.element.addEventListener('click', (e) => {
        e.preventDefault(e);
        this.setActiveMeasure(measureObject);
        this.setCurrentCount(this.minCount);
      });
    });
  }

  /**
   * Подхватывает в свойства html элементы цены, старой цены, кнопки +/- и инпут ввода
   */
  initInterface() {
    this.priceField = this.element.querySelector('[data-field-price]');
    this.oldPriceField = this.element.querySelector('[data-field-oldprice]');
    this.buttonDec = this.element.querySelector('[data-product-dec]');
    this.buttonInc = this.element.querySelector('[data-product-inc]');
    this.inputQuantity = this.element.querySelector('[data-product-value]');
    this.conversions = this.element.querySelector('[data-conversion-values]');

    if (!this.priceField || !this.inputQuantity) {
      return false;
    }

    return true;
  }

  initMeasures() {
    this.measureList = [];
    this.activeMeasure = {};

    this.measureListElement = this.element.querySelector('[data-measure-list]');
    this.inputMeasure = this.element.querySelector('[data-product-measure-value]');
    if (!this.measureListElement || !this.inputMeasure) {
      return false;
    }

    const measureList = this.measureListElement.querySelectorAll('[data-measure-id]');
    if (!measureList) {
      return false;
    }

    measureList.forEach((measureItem) => {
      const measureObject = {};
      measureObject.id = measureItem.dataset.measureId;
      measureObject.name = measureItem.innerHTML.trim();
      measureObject.element = measureItem;
      measureObject.minCount = measureItem.dataset.minCount;
      if (measureObject.id && measureObject.name) {
        this.measureList.push(measureObject);
        if (measureItem.classList.contains('_active')) {
          this.activeMeasure = measureObject;
        }
      }
    });

    if (!this.measureList) {
      return false;
    }

    if (!this.activeMeasure) {
      this.setActiveMeasure(this.measureList[0]);
    }

    return true;
  }

  /**
   * Устанавливает объект цены активным
   * Снимает/навешивает класс _active на меры
   * Возращает true, если смена произошла
   * @param measureObject
   * @returns {boolean}
   */
  setActiveMeasure(measureObject) {
    if (this.activeMeasure.id !== measureObject.id || !measureObject.element.classList.contains('_active')) {
      const oldElement = this.measureListElement.querySelector('._active');
      if (oldElement) {
        oldElement.classList.remove('_active');
      }
      this.activeMeasure = measureObject;
      this.inputMeasure.dataset.productMeasureValue = measureObject.id;
      this.inputMeasure.innerHTML = measureObject.name;
      this.minCount = measureObject.minCount;
      measureObject.element.classList.add('_active');
      return true;
    }

    return false;
  }


  setCurrentCount(count) {
    this.inputQuantity.value = count;
    this.update(this.product, this.activeMeasure.id, count);
  }

  /**
   * Преобразует количество к нужному формату
   * @param count
   * @returns {string | *}
   */
  prepareCount(count) {
    const min = this.minCount;
    count = parseFloat(count) || parseFloat(min);
    count = count.toFixed(1);
    if (count < min) {
      count = min;
    }
    const countInt = parseInt(count * 10, 10);
    if (countInt % 10 === 0) {
      count = parseInt(count, 10);
    }

    return count;
  }

  /**
   * Вернет число из поля ввода колличество
   * Если в поле не корректные данные - вернет колличественный минимум по мере
   * @returns {number|*}
   */
  getCurrentCount() {
    let count = this.inputQuantity.value || this.minCount;
    count = this.prepareCount(count);

    return count;
  }

  render(me, data) {
    if (data.response) {
      const response = data.response;
      if (response.price) {
        me.priceField.innerHTML = Math.round(response.price);
      }

      if (me.oldPriceField && response.oldPrice && response.oldPrice !== 0) {
        me.oldPriceField.innerHTML = Math.round(response.oldPrice);
        me.oldPriceField.classList.remove('_hide');
        me.priceField.parentNode.classList.add('_green');
      } else {
        me.oldPriceField.classList.add('_hide');
        me.priceField.parentNode.classList.remove('_green');
      }
      me.conversions.innerHTML = '';
      if (response.converted) {
        response.converted.forEach((stringItem) => {
          const newElement = document.createElement('li');
          newElement.innerHTML = stringItem;
          me.conversions.appendChild(newElement);
        });
      }
    }
  }

  update(productId, measureId, quantity) {
    const data = {};
    data.product = productId;
    data.measure = measureId;
    data.quantity = quantity;
    this.request(this.url, data, this.render);
  }

  request(route, request, callback) {
    const jax = new Jax(route, 'POST', true);
    jax.send(request).then((data) => {
      if (data.success && callback) {
        callback(this, data);
      } else {
        console.log(data.error);
      }
    });
  }

  /**
   * "Умное" округление вверх. Округлит 5.1 до 6, но 4.00000001 до 4
   *
   * @param value
   * @returns {number}
   */
  static smartCeil(value) {
    if (value - Math.floor(value) < 0.00001) {
      return Math.floor(value);
    }
    return Math.ceil(value);
  }
}

document.addEventListener('DOMContentLoaded', initProduct);